import React from 'react'
import PropTypes from 'prop-types'
import {
  ReferenceField,
  TextField,
} from 'react-admin'

import { useGetReferenceBySource } from '../utils'

const Field =  ({source, optionText, ...props}) => {
  const reference = useGetReferenceBySource(source)
  return (
    <ReferenceField source={`${source}Id`} reference={reference} {...props}>
      <TextField source={optionText} />
    </ReferenceField>
  )
}

Field.defaultProps = {
  optionText: 'name',
  addLabel: true,
  link: false,
}

Field.propTypes = {
  source: PropTypes.string.isRequired,
  optionText: PropTypes.string,
  addLabel: PropTypes.bool,
  link: PropTypes.bool,
}

export default Field
