import React from 'react'
import { DateField } from 'react-admin'
import PropTypes from 'prop-types'

const Field = props => <DateField {...props} />

Field.defaultProps = {
  locales: "es-ES",
  addLabel: true,
}

Field.propTypes = {
  locales: PropTypes.string,
  addLabel: PropTypes.bool,
  source: PropTypes.string.isRequired,
}

export default Field
