import React from 'react'

import { ReferenceInput, SelectInput, required } from 'react-admin'

const Input = props => (
  <>
    <ReferenceInput
      {...props}
      label="Brand 1"
      source="brandsIds[0]"
      reference="Brands"
      parse={v => (v ? parseInt(v, 10) : null)}
      validate={required()}
      perPage={1000}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <br key="enter" />
    <ReferenceInput
      key="brand2"
      {...props}
      label="Brand 2"
      source="brandsIds[1]"
      reference="Brands"
      parse={v => (v ? parseInt(v, 10) : null)}
      perPage={1000}
      sort={{ field: 'name', order: 'ASC' }}
      allowEmpty
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </>
)

export default Input
