import React from 'react'

import { useGetOne } from 'react-admin'

import { useGetReferenceBySource } from '../utils'

const FieldWithCurrency = ({value, referenceId, sourceReference}) => {
  const reference = useGetReferenceBySource(sourceReference)
  const { data, loading } = useGetOne(reference, referenceId)
  if (loading === true) return null

  return `${value}${data.symbol}`
}

const Field = ({source, sourceReference, record}) => {
  const value = record[source]
  const valueCurrency = record[`${sourceReference}Id`]
  if (value === undefined) return <div>-</div>

  if(valueCurrency !== undefined){
    return <FieldWithCurrency value={value} referenceId={valueCurrency} sourceReference={sourceReference} />
  }

  return `${value}`
}

Field.defaultProps = {
  source: 'amount',
  sourceReference: 'currency',
  addLabel: true,
}

export default Field
