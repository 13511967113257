import React from 'react'
import PropTypes from 'prop-types'
import {
  required,
  ReferenceInput,
  SelectInput
} from 'react-admin'

import { useGetReferenceBySource } from '../utils'

const Input = ({source, optionText, optional, ...props}) => {
  const reference = useGetReferenceBySource(source)
  return (
    <ReferenceInput
      source={`${source}Id`}
      reference={reference}
      validate={!optional && [required()]}
      sort={{ field: optionText, order: 'ASC' }}
      {...props}
    >
      <SelectInput optionText={optionText} {...props} />
    </ReferenceInput>
  )
}

Input.defaultProps = {
  optionText: 'name',
  optional: false,
  parse: v => v ? parseInt(v, 10) : null,
  format: v => v ? String(v) : null,
  perPage: 1000,
}

Input.propTypes = {
  source: PropTypes.string.isRequired,
  optionText: PropTypes.string,
  optional: PropTypes.bool,
  parse: PropTypes.func,
  format: PropTypes.func,
  perPage: PropTypes.number,
}

export default Input
