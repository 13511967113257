import React from 'react'
import { TextField, ReferenceField } from 'react-admin'
import PropTypes from 'prop-types'

const Field = props => (
  <ReferenceField {...props}>
    <TextField source="name" />
  </ReferenceField>
)

Field.defaultProps = {
  addLabel: true,
  reference: 'Users',
  link: 'show',
}

Field.propTypes = {
  addLabel: PropTypes.bool,
  reference: PropTypes.string,
  link: PropTypes.string,
  source: PropTypes.string.isRequired,
}

export default Field
