/* eslint-disable no-param-reassign */
import React from 'react'
import { TextField } from 'react-admin'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    red: { color: '#EC5B55', fontWeight: 'bold', },
    blue: { color: '#4192F7', fontWeight: 'bold', },
    green: { color: '#74CF95', fontWeight: 'bold', },
})

export const CHOICES = [
  { id: 'insert', name: 'INSERT', color: 'green' },
  { id: 'update', name: 'UPDATE', color: 'blue' },
  { id: 'delete', name: 'DELETE', color: 'red' },
]

const Field = ({source, record, ...props}) => {
  const classes = useStyles()
  const choise = CHOICES.find(c => c.id === record[source])
  if(choise === undefined || record === null) return null

  props.record = {
    [source]: record ? choise.name : '',
  }
  props.source = source
  return <TextField className={classes[choise.color]} {...props} />
}

Field.defaultProps = {
  source: 'type',
  addLabel: true,
}

export default Field
