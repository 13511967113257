const tokenId = 'token'

export const getToken = () => localStorage.getItem(tokenId)
export const setToken = newToken => localStorage.setItem(tokenId, newToken)
export const deleteToken = () => localStorage.removeItem(tokenId)

export default () => {
  return [
    // getToken
    getToken,
    // deleteToken()
    deleteToken
  ]
}
