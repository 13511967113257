import React from 'react'
import { TextInput } from 'react-admin'

const Input = props => (
  <TextInput
    {...props}
  />
)

Input.defaultProps = {
  source: 'model',
}

export default Input
