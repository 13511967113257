/* eslint-disable no-param-reassign */
import React from 'react'
import { TextField } from 'react-admin'

const Field = ({source, record, ...props}) => {
  const choise = record[source]
  if(choise === undefined || record === null) return null

  props.record = {
    [source]: choise,
  }
  props.source = source
  return <TextField {...props} />
}

Field.defaultProps = {
  source: 'model',
  addLabel: true,
}

export default Field
