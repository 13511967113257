import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin'

import {
  useMaxLengthValidator,
  useStringValidator,
  useUniqueValidator,
} from '../../utils'

import {
  DateInput,
} from '../../inputs'

export default props => {
  const maxLengthValidator = useMaxLengthValidator(props.resource)
  const stringValidator = useStringValidator()
  const uniqueValidator = useUniqueValidator(props.resource, 'short', props.id)
  return (
    <Edit {...props}>
      <SimpleForm>
        <DateInput source="date" validate={required()} />
        <TextInput source="short" validate={[required(), maxLengthValidator, stringValidator, uniqueValidator]} />
        <TextInput source="name" validate={required()} />
      </SimpleForm>
    </Edit>
  )
}
