import React from 'react'
import { DateField } from './index'

const Field = props => <DateField showTime {...props} />

Field.defaultProps = {
  addLabel: true,
}

export default Field
