import React from 'react'

import get from 'lodash/get'

const Field = ({ record }) => (
  <span>{['name', 'surname'].map(f => get(record, f)).join(' ')}</span>
)

Field.defaultProps = {
  addLabel: true,
  label: 'Full name'
}
export default Field
