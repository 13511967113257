export default source => {
  switch(source){
    case "campaignLevel":
      return "CampaignLevels"
    case "country":
      return "Countries"
    case "channelSource":
      return "ChannelSources"
    case "campaignType":
      return "CampaignTypes"
    case "destination":
      return "Destinations"
    case "agency":
      return "Agencies"
    case "TypeEvent":
      return "TypeEvent"
    case "campaign":
      return "Campaigns"
    case "objective":
      return "Objectives"
    case "currency":
      return "Currencies"
    case "brands":
      return "Brands"
    case "createdBy":
      return "Users"  
    default:
      console.log(
          `%cError useGetReferenceBySource: source ${source} not found`,
          'color: red',
        )
      return undefined
  }
}
