import React from 'react'
import {
  Edit,
  SimpleForm,
  TextField,
} from 'react-admin'

import { RoleInput } from '../../inputs'
import { FullnameField } from '../../fields'

export default props => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextField source="id" />
        <FullnameField />
        <TextField source="email" />
        <RoleInput />
      </SimpleForm>
    </Edit>
  )
}
