import React from 'react'
import {
  Create,
  TabbedForm,
  FormTab,
  TextInput,
  NumberInput,
  usePermissions,
  FormDataConsumer,
  minValue,
} from 'react-admin'

import {
  DateInput,
  SelectorInput,
  BrandsInput
} from '../../inputs'

import {
  useMaxLengthValidator,
  useStringValidator,
  useWithBrands,
  useIntervalDateValidator
} from '../../utils'

export default props => {
  const { permissions } = usePermissions()
  const withBrands = useWithBrands()
  const maxLengthValidator = useMaxLengthValidator('description')
  const intervalDateValidator = useIntervalDateValidator()
  const stringValidator = useStringValidator()

  const minValueValidator = [minValue(0)]

  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label="Label parameters">
          <SelectorInput source="campaignLevel" />
          <SelectorInput source="country" />
          <SelectorInput source="channelSource" />
          <SelectorInput source="campaignType" />
          <SelectorInput source="destination" />
          <SelectorInput source="agency" />
          <SelectorInput source="campaign" />
          <SelectorInput source="objective" />
          <FormDataConsumer>
            {({ formData, ...rest }) => withBrands(formData.campaignLevelId) && <BrandsInput {...rest} />}
          </FormDataConsumer>
          <TextInput source="description" validate={[maxLengthValidator, stringValidator]} />
        </FormTab>
        {['ROLE_ADMIN', 'ROLE_COSTS'].includes(permissions) && (
          <FormTab label="Cost allocation">
            <NumberInput source="amount" validate={minValueValidator} />
            <SelectorInput source="currency" optional />
            <NumberInput source="impressions" validate={minValueValidator} step={1} />
            <DateInput source="initialDate" />
            <DateInput source="endDate" validate={intervalDateValidator} />
          </FormTab>
        )}
      </TabbedForm>
    </Create>
  )
}
