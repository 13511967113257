
import { useState, useEffect } from 'react'
import { useDataProvider } from 'react-admin'

export default (resource, field, id = null) => {
  const [actualVal, setActualVal] = useState()
  const dataProvider = useDataProvider()
  useEffect(() => {
    if (id)
      dataProvider.getOne(resource, { id })
        .then(({ data }) => {
          setActualVal(data[field])
        })
  }, [resource, id, field, dataProvider])

  if (actualVal === undefined && id !== null) return undefined

  return async value => {
    if (value === actualVal) return undefined
    const { total } = await dataProvider.getList(
      resource,
      {
        filter: { [field]: value },
      },
    )
    return total === 0
      ? undefined
      : { message: 'ra.validation.unique', args: { value } }
  }
}
