import React from 'react'
import {
  Datagrid,
  List,
  Filter,
  TextInput,
  TextField,
} from 'react-admin'

import { SelectorInput } from '../../inputs'

import { CodeField, SelectorField, AmountField, DateField, UserField } from '../../fields'


const Filters = props => {
  return (
    <Filter {...props}>
      <TextInput source="code" alwaysOn />
      <SelectorInput source="campaignLevel" optional />
      <SelectorInput source="channelSource" optional />
      <SelectorInput source="country" optional />
      <SelectorInput source="createdBy" optional />
    </Filter>
  )
}

export default props => (
  <List
    {...props}
    bulkActionButtons={false}
    exporter={false}
    perPage={25}
    title={props.options.label}
    filters={<Filters />}
    sort={{ field: 'createdAt', order: 'DESC' }}
  >
    <Datagrid rowClick="edit">
      <CodeField sortable={false} />
      <DateField source="createdAt" />
      <UserField source="createdBy" />
      <AmountField />
      <TextField source="impressions" />
      <DateField source="initialDate" />
      <DateField source="endDate" />
      <SelectorField source="campaignLevel" sortable={false} />
      <SelectorField source="channelSource" sortable={false} />
      <SelectorField source="country" sortable={false} />
    </Datagrid>
  </List>
)
