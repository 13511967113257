import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin'

import {
  useMaxLengthValidator,
  useStringValidator,
  useUniqueValidator,
} from '../../utils'

import {
  DateInput,
} from '../../inputs'

export default props => {
  const maxLengthValidator = useMaxLengthValidator(props.resource)
  const stringValidator = useStringValidator()
  const uniqueValidator = useUniqueValidator(props.resource, 'short')
  return (
    <Create {...props}>
      <SimpleForm>
        <DateInput source="date" validate={required()} />
        <TextInput source="short" validate={[required(), maxLengthValidator, stringValidator, uniqueValidator]} />
        <TextInput source="name" validate={required()} />
      </SimpleForm>
    </Create>
  )
}
