import decodeJwt from 'jwt-decode'

import { getToken, setToken, deleteToken } from '../../utils'

export default {
  // called when the user attempts to log in
  login: token => {
    setToken(token)
    return Promise.resolve()
  },
  // called when the user clicks on the logout button
  logout: () => {
    deleteToken()
    return Promise.resolve()
  },
  // called when the API returns an error
  checkError: ({ graphQLErrors }) => {
    if (graphQLErrors[0].code === 401) {
      deleteToken()
      return Promise.reject()
    }
    return Promise.resolve()
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    if (getToken()) {
      return Promise.resolve()
    }
    localStorage.setItem('redirectPath', window.location.hash)
    return Promise.reject()
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const token = getToken()
    if (token) {
      const {role} = decodeJwt(token)
      return Promise.resolve(role)
    }
    return Promise.reject()
  },
}

export const hasPermission = (
  permissions = [],
  Component,
  roles = [],
) => {
  if (permissions == null) return null
  /* eslint-disable-next-line no-param-reassign */
  if (typeof roles === 'string') roles = [roles]
  roles.push('ROLE_ADMIN')
  return roles.includes(permissions)
    ? Component
    : null
}
