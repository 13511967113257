import React from 'react'
import get from 'lodash/get'

import { ChipField } from 'react-admin'

export const CHOICES = [
  { id: 'ROLE_ADMIN', name: 'Admin' },
  { id: 'ROLE_OWNER', name: 'Owner' },
  { id: 'ROLE_COSTS', name: 'Costs' },
  { id: 'ROLE_VIEW', name: 'View' },
]

const Field = ({record, ...props}) => {
  const value = get(record, props.source)
  const choice = CHOICES.find(c => c.id === value)

  if (choice === undefined) return null

  return <ChipField record={{[props.source]: choice.name}} {...props} />
}

Field.defaultProps = {
  addLabel: true,
  source: 'role',
}

export default Field
