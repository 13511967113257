import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin'

import {
  useOneCharacterValidator,
  useUniqueValidator,
} from '../../utils'

export default props => {
  const oneCharactherValidator = useOneCharacterValidator()
  const uniqueValidator = useUniqueValidator(props.resource, 'symbol', props.id)
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="symbol" validate={[required(), oneCharactherValidator, uniqueValidator]} />
        <TextInput source="name" validate={required()} />
      </SimpleForm>
    </Edit>
  )
}
