import english from 'ra-language-english'

const { ra } = english

export default {
  ra: {
    ...ra,
    validation: {
      ...ra.validation,
      noSpaces: 'Spaces are not allowed',
      noUnderscores: 'Underscores are not allowed',
      oneCharacter: 'Only 1 character is allowed',
      endDate:'The end date must be greater than the initial date',
      unique: '%{value} already used',
    },
    notification: {
      ...ra.notification,
      copied: 'Copied to clipboard',
      regenerateCode: {
        send: 'Regenerating code. Please wait...',
        end: 'Code regenerated :)'
      },
    },
  },
}
