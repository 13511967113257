import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from '@material-ui/core'
import { MenuItemLink, getResources, usePermissions } from 'react-admin'
import ConfigIcon from '@material-ui/icons/Settings'
import ListsIcon from '@material-ui/icons/ListAlt'

import Submenu from './Submenu'

import { defaultId } from '../models/config'

export default ({ onMenuClick, dense, logout }) => {
  const [submenu, setSubmenu] = useState({
    enums: false,
  })
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const open = useSelector(state => state.admin.ui.sidebarOpen)
  const resources = useSelector(getResources)
  const { permissions } = usePermissions()

  const handleToggle = menu => {
      setSubmenu(state => ({ ...state, [menu]: !state[menu] }))
  }

  const MenuItem = ({resource}) =>  (
    <MenuItemLink
      to={`/${resource.name}`}
      primaryText={
        (resource.options && resource.options.label) ||
        resource.name
      }
      leftIcon={
        resource.icon ? <resource.icon /> : null
      }
      onClick={onMenuClick}
      sidebarIsOpen={open}
    />
  )

  const arrayModels = []

  return (
    <div>
      {resources.map(resource => {

        if(!resource.hasList) return null

        if(!resource.icon){
          arrayModels.push(resource)
          return null
        }

        return <MenuItem key={resource.name} resource={resource} />
      }
    )}
      {arrayModels.length ? (
        <Submenu
          handleToggle={() => handleToggle('enums')}
          isOpen={submenu.enums}
          sidebarIsOpen={open}
          name="Lists"
          dense={dense}
          icon={<ListsIcon />}
        >
          {arrayModels.map(resource => <MenuItem key={resource.name} resource={resource} />)}
        </Submenu>
      ) : null}
      {['ROLE_ADMIN'].includes(permissions)
      ? <MenuItemLink
          to={`/Configs/${defaultId}`}
          primaryText="Configuration"
          leftIcon={<ConfigIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      : null}
      {isXSmall && logout}
    </div>
  )
}
