import React from 'react'
import PropTypes from 'prop-types'
import {
  ReferenceArrayInput,
  SelectArrayInput
} from 'react-admin'

import { capitalize } from 'lodash'

import { useGetReferenceBySource } from '../utils'

const Input = ({source, optionText, ...props}) => {
  const reference = useGetReferenceBySource(source)
  return (
    <ReferenceArrayInput source={`${source}Ids`} reference={reference} label={capitalize(source)} sort={{field: optionText, order: 'ASC'}} {...props}>
      <SelectArrayInput optionText={optionText} />
    </ReferenceArrayInput>
  )
}

Input.defaultProps = {
  optionText: 'name',
  parse: array => array?.map(v => parseInt(v, 10)),
  format: array => array?.map(v => v ? String(v) : null),
  perPage: 200,
}

Input.propTypes = {
  source: PropTypes.string.isRequired,
  optionText: PropTypes.string,
  parse: PropTypes.func,
  format: PropTypes.func,
  perPage: PropTypes.number,
}

export default Input
