import React from 'react'
import {
  Datagrid,
  List,
  TextField,
  Filter,
  SearchInput
} from 'react-admin'

import {
  DateTimeField,
} from '../../fields'

const Filters = props => {
  return (
    <Filter {...props}>
      <SearchInput source="name" alwaysOn />
    </Filter>
  )
}

export default props => (
  <List
    {...props}
    bulkActionButtons={false}
    exporter={false}
    perPage={25}
    filters={<Filters />}
    title={props.options.label}
    sort={{ field: 'date', order: 'DESC' }}
  >
    <Datagrid rowClick="edit">
      <DateTimeField 
        source="date" 
        showTime={false}
        options={{
          month: 'short',
          year: 'numeric',
        }}
      />
      <TextField source="short" />
      <TextField source="name" />
    </Datagrid>
  </List>
)
