import React from 'react'
import {
  Edit,
  TabbedForm,
  FormTab,
  TextField,
  NumberInput,
  Toolbar,
  SaveButton,
  DeleteButton,
  ListButton,
  usePermissions,
  minValue,
} from 'react-admin'

import {
  DateInput,
  SelectorInput,
} from '../../inputs'

import {
  CodeField,
  SelectorField,
  SelectorArrayField,
} from '../../fields'

import {
  useIntervalDateValidator,
} from '../../utils'

const ModelToolbar = props => {
  const { permissions } = usePermissions()
  return (
    <Toolbar {...props}>
      {['ROLE_ADMIN', 'ROLE_COSTS'].includes(permissions) && <SaveButton redirect="edit" />}
      <ListButton />
      {/* {['ROLE_ADMIN'].includes(permissions) && <RegenerateCodeButton />} */}
      {['ROLE_ADMIN'].includes(permissions) && <DeleteButton />}
    </Toolbar>
  )
}

export default props => {
  const { permissions } = usePermissions()
  const intervalDateValidator = useIntervalDateValidator()

  const minValueValidator = [minValue(0)]

  return (
    <Edit {...props}>
      <TabbedForm redirect="edit" toolbar={<ModelToolbar />}>
        <FormTab label="Label parameters">
          <CodeField />
          <SelectorField source="campaignLevel" />
          <SelectorField source="country" />
          <SelectorField source="channelSource" />
          <SelectorField source="campaignType" />
          <SelectorField source="destination" />
          <SelectorField source="agency" />
          <SelectorField source="campaign" />
          <SelectorField source="objective" />
          <SelectorArrayField source="brands" />
          <TextField source="description" />
        </FormTab>
        {['ROLE_ADMIN', 'ROLE_COSTS'].includes(permissions) && (
          <FormTab label="Cost allocation">
            <CodeField />
            <NumberInput source="amount" validate={minValueValidator} />
            <SelectorInput source="currency" optional />
            <NumberInput source="impressions" validate={minValueValidator} step={1} />
            <DateInput source="initialDate" />
            <DateInput source="endDate" validate={intervalDateValidator} />
          </FormTab>
        )}
      </TabbedForm>
    </Edit>
  )
}
