import React from 'react'
import {
  Edit,
  NumberInput,
  TabbedForm,
  FormTab,
  Toolbar,
  SaveButton,
  required,
} from 'react-admin'

import {
  startCase,
} from 'lodash'

const elements = [
  'CampaignLevels',
  'Countries',
  'ChannelSources',
  'CampaignTypes',
  'Destinations',
  'Agencies',
  'TypeEvent',
  'Campaigns',
  'Objectives',
  'Brands',
]

const ModelToolbar = props => (
  <Toolbar {...props}>
    <SaveButton redirect="edit" />
  </Toolbar>
)

export default props => (
  <Edit {...props} title="Config">
    <TabbedForm toolbar={<ModelToolbar />}>
      <FormTab label="Max Characters">
        {elements.map(id => <NumberInput key={id} source={`maxCharacters.${id}`} label={startCase(id)} min={1} step={1} validate={required()} />)}
      </FormTab>
    </TabbedForm>
  </Edit>
)
