import React from 'react'
import { DateInput } from 'react-admin'
import PropTypes from 'prop-types'

const Input = props => <DateInput {...props} />

Input.defaultProps = {
  locales: 'es-ES',
}

Input.propTypes = {
  locales: PropTypes.string,
  source: PropTypes.string.isRequired,
}

export default Input
