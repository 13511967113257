import React from 'react'
import { SelectInput } from 'react-admin'

import { CHOICES } from '../fields/TypeField'

const Input = props => (
  <SelectInput
    {...props}
    choices={CHOICES}
  />
)

Input.defaultProps = {
  source: 'type',
}

export default Input
