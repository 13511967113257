import React from 'react'
import PropTypes from 'prop-types'
import {
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin'

import { useGetReferenceBySource } from '../utils'

const Field =  ({source, optionText, ...props}) => {
  const reference = useGetReferenceBySource(source)
  return (
    <ReferenceArrayField source={`${source}Ids`} reference={reference} link={false} {...props}>
      <SingleFieldList linkType={false}>
        <ChipField source={optionText} />
      </SingleFieldList>
    </ReferenceArrayField>
  )
}

Field.defaultProps = {
  optionText: 'name',
  addLabel: true,
}

Field.propTypes = {
  source: PropTypes.string.isRequired,
  optionText: PropTypes.string,
  addLabel: PropTypes.bool,
}

export default Field
