import React from 'react'
import {
  List,
  Datagrid,
  EmailField,
  Filter,
  TextInput
} from 'react-admin'

import { RoleInput } from '../../inputs'

import { RoleField, FullnameField } from '../../fields'

const Filters = props => {
  return (
    <Filter {...props}>
      <TextInput source="name" />
      <TextInput source="surname" />
      <RoleInput />
    </Filter>
  )
}

export default props => (
  <List
    {...props}
    title="Examples"
    filters={<Filters />}
    bulkActionButtons={false}
    exporter={false}
    perPage={25}
  >
    <Datagrid rowClick="edit">
      <FullnameField />
      <EmailField source="email" />
      <RoleField />
    </Datagrid>
  </List>
)
