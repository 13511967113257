import React from 'react'
import PropTypes from 'prop-types'
import { SelectInput } from 'react-admin'

import { CHOICES } from '../fields/RoleField'

const Input = props => (
  <SelectInput choices={CHOICES} {...props} />
)

Input.defaultProps = {
  source: 'role',
}

Input.propTypes = {
  source: PropTypes.string,
}

export default Input
