import React, { useEffect, useState} from 'react'
import { Admin, Resource, Loading } from 'react-admin'
import { dataProvider, authProvider, hasPermission } from './providers/CrowdGraphQL'
import i18nProvider  from './providers/i18nProvider'
import layout from './layout'
import loginPage from './pages/loginPage'

import * as Examples from './models/examples'
import * as Logs from './models/logs'
import * as Users from './models/users'
import * as Enums from './models/enums'
import * as Currencies from './models/currencies'
import * as Campaigns from './models/campaigns'
import * as Labels from './models/labels'
import * as Configs from './models/config'

import { useIsDev } from './utils'

const App = () => {
  const [provider, setProvider] = useState(null)
  const isDev = useIsDev()

  useEffect(
    () => {
      const callback = props => {
        setProvider(() => props)
      }
      dataProvider(callback)
    },
    [],
  )

  if(provider === null){
    return (
      <Loading
        loadingPrimary="Loading"
        loadingSecondary="please, wait for a while"
      />
    )
  }

  const appParameters = { dataProvider: provider, layout, i18nProvider, authProvider, loginPage}

  return (
    <Admin {...appParameters}>
      {permissions => [
        <Resource name="Labels" {...Labels} create={hasPermission(permissions, Labels.create, ['ROLE_ADMIN', 'ROLE_COSTS', 'ROLE_OWNER'])} />,
        <Resource name="CampaignLevels" options={{ label: 'Campaign Levels' }} {...hasPermission(permissions, Enums)} />,
        <Resource name="Countries" options={{ label: 'Countries' }} {...hasPermission(permissions, Enums)} />,
        <Resource name="ChannelSources" options={{ label: 'Channel Sources' }} {...hasPermission(permissions, Enums)} />,
        <Resource name="CampaignTypes" options={{ label: 'Campaign Types' }} {...hasPermission(permissions, Enums)} />,
        <Resource name="Destinations" options={{ label: 'Destinations' }} {...hasPermission(permissions, Enums)} />,
        <Resource name="Agencies" options={{ label: 'Agencies' }} {...hasPermission(permissions, Enums)} />,
        <Resource name="TypeEvent" options={{ label: 'Type of Event' }} {...hasPermission(permissions, Enums)} />,
        <Resource name="Campaigns" options={{ label: 'Campaigns' }} {...hasPermission(permissions, Campaigns)} />,
        <Resource name="Objectives" options={{ label: 'Objectives' }} {...hasPermission(permissions, Enums)} />,
        <Resource name="Brands" options={{ label: 'Brands' }} {...hasPermission(permissions, Enums)} />,
        <Resource name="Currencies" {...hasPermission(permissions, Currencies)} />,
        <Resource name="Logs" {...hasPermission(permissions, Logs)} />,
        <Resource name="Users" {...hasPermission(permissions, Users)} />,
        <Resource name="Configs" {...hasPermission(permissions, Configs)} />,
        isDev && <Resource name="Examples" {...Examples} />,
      ]}
    </Admin>
  )
}

export default App
