import { useGetOne } from 'react-admin'

import { defaultId } from '../models/config'

export default key => {
  const {data} = useGetOne('Configs', defaultId)

  if (data === undefined) return false

  if (key === 'description' ) {
    return (value, allValues) => {
      const max = allValues.channelSourceId === 9 ? 15 : 40
      return value && value.length <= max
        ? undefined
        : { message: 'ra.validation.maxLength', args: { max } }
    }
  }

  const max = data.maxCharacters[key]
  if (max === undefined) {
    console.log(
        `%cError useMaxLengthValidator: index ${key} not found.`,
        'color: red',
      )
    return false
  }
  return value => value && value.length <= max
          ? undefined
          : { message: 'ra.validation.maxLength', args: { max } }
}
